/* ONSOFT */
// import { isArray, map } from 'lodash';

import { Registry, RegistryItem, TypedVariableModel } from '@grafana/data';

import { ALL_VARIABLE_VALUE } from '../variables/constants';


export interface FormatOptions {
  value: any;
  text: string;
  args: string[];
}

export default interface CgnFormatRegistryItem extends RegistryItem {
  formatter(options: FormatOptions, variable: TypedVariableModel): string;
}

export enum cgnFormatRegistryID {
  cgnKey = 'cgnkey',
}

export const cgnFormatRegistry = new Registry<CgnFormatRegistryItem>(() => {
  const formats: CgnFormatRegistryItem[] = [
    /* ONCGN */
    {
      id: cgnFormatRegistryID.cgnKey,
      name: 'onCognos Key Values',
      description: 'Key Values are separated by | character',
      formatter: ({ value }) => {

        //if (isArray(value)) {
        //  return map(value, (v: string) => formatCgnKey(v) ).join('|');
        //}
        
        return '';

      },
    },    
  ];

  return formats;
});

export function isAllValue(value: any) {
  return value === ALL_VARIABLE_VALUE || (Array.isArray(value) && value[0] === ALL_VARIABLE_VALUE);
}

