/* ONSOFT */
import { escape, isString, property, isArray, map } from 'lodash';
import { formatRegistry } from '@grafana/scenes';
import { VariableFormatID } from '@grafana/schema';

import { isAdHoc } from '../variables/guard';

import { getVariableWrapper } from './LegacyVariableWrapper';
import { cgnFormatRegistry } from './cgnFormatRegistry';

export function formatVariableValue(value: any, format?: any, variable?: any, text?: string): string {
  // for some scopedVars there is no variable
  variable = variable || {};

  if (value === null || value === undefined) {
    return '';
  }

  if (isAdHoc(variable) && format !== VariableFormatID.QueryParam) {
    return '';
  }

  // if it's an object transform value to string
  if (!Array.isArray(value) && typeof value === 'object') {
    value = `${value}`;
  }

  if (typeof format === 'function') {
    return format(value, variable, formatVariableValue);
  }

  if (!format) {
    format = VariableFormatID.Glob;
  }

  // some formats have arguments that come after ':' character
  let args = format.split(':');
  if (args.length > 1) {
    format = args[0];
    args = args.slice(1);
  } else {
    args = [];
  }

    // 2023/03/17 <adriano@onsoft.com.br> Adriano J Rodrigues
    // ONSOFT - inicio

    let cgnFormatItem = cgnFormatRegistry.getIfExists(format);

    //const cgnFormatVariable = getVariableWrapper(variable, value, text ?? value);    

    if (cgnFormatItem) {
      // return cgnFormatItem.formatter(value, args);

      if (isArray(value)) {
         return map(value, (v: string) => formatCgnKey(v) ).join('|');
      }

      return formatCgnKey(value);
    }
    // ONSOFT - fim 

  let formatItem = formatRegistry.getIfExists(format);

  if (!formatItem) {
    console.error(`Variable format ${format} not found. Using glob format as fallback.`);
    formatItem = formatRegistry.get(VariableFormatID.Glob);
  }

  const formatVariable = getVariableWrapper(variable, value, text ?? value);
  return formatItem.formatter(value, args, formatVariable);
}

/* ONSOFT */
function formatCgnKey(str: string): string {
  const posStart = str.indexOf('[')+1;
  const posEnd = str.indexOf(']');

 if (posStart < posEnd) {
   return str.substring(posStart,posEnd);
 }
 return str;
}
