// ONSOFT.AJR
import { cx } from '@emotion/css';
import React from 'react';
// import { onsSetParam, onsSetTimeRange } from '/public/onsoft/lib/utils';

import { useStyles2 } from '../../themes';
import { getCellLinks } from '../../utils';
import { Button, clearLinkButtonStyles } from '../Button';
import { DataLinksContextMenu } from '../DataLinks/DataLinksContextMenu';

import { TableCellProps } from './types';

export const ButtonVarCell = (props: TableCellProps) => {
  const { field, cell, tableStyles, row, cellProps } = props;

  const lMyString = cell.value;
  const lparts = lMyString.split('|');
  const lVar = lparts[0]; // Var
  const lId = lparts[1]; // Id
  const lText = lparts[2]; // Id
  return (
   <div {...cellProps} className={tableStyles.cellContainer}>
      {<button onClick={() => { onsSetParam(lVar,lId); }} class="ons-button-var">{lText}</button>}
    </div>
  );
};
